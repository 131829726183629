import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import App from "./App.vue";
import router from "./router";
import "./assets/styles.scss";

const originalFetch = window.fetch;
// прокидывание init.credentials = "include"; на все запросы для передачи cookies
window.fetch = (input, init?): Promise<Response> => {
  if (!init) {
    init = {};
  }

  init.credentials = "include";

  return originalFetch(input, init);
};

createApp(App)
  .use(PrimeVue, { ripple: true })
  .use(router)
  .use(ToastService)
  .use(createPinia())
  .directive("tooltip", Tooltip)
  .mount("#app");
