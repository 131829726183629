import { ref } from "vue";
import type { ApiResponseSingleType } from "@/types/BasicType";

const userName = ref("");
const userEmail = ref("");
const fetchPopUpUser = async () => {
  return await fetch(process.env.VUE_APP_BASE_API_URL + "/admin/user/get")
    .then((r) => {
      if (r.ok) {
        return r.json();
      }
      throw new Error(r.statusText);
    })
    .then(
      (
        data: ApiResponseSingleType<{
          Lastname: string;
          Firstname: string;
          Middlename: string;
          Email: string;
        }>
      ) => {
        if (data.status === "OK") {
          const userData = data.data;
          userName.value = `${userData.Lastname} ${userData.Firstname} ${userData.Middlename}`;
          userEmail.value = userData.Email;
        } else {
          throw new Error(data.status);
        }
      }
    );
};

export function usePopUpUserService() {
  return {
    fetchPopUpUser,
    userName,
    userEmail,
  };
}
