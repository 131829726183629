import { ref } from "vue";
import { defineStore } from "pinia";
import {
  requestHandler,
  setFormData,
  setQueryParams,
} from "@/assets/js/functions";
import type {
  ApiDataResponseType,
  ApiResponseSingleType,
} from "@/types/BasicType";
import type { ProjectItemType, ProjectModelType } from "@/types/ProjectType";

type ProjectListResponse = ApiDataResponseType<ProjectItemType>;
type ProjectActionResponse = ApiResponseSingleType<ProjectItemType>;

export const useProjectStore = defineStore("project", () => {
  const projectsList = ref<ProjectItemType[]>([]);
  const isLoading = ref<boolean>(true);
  const isNotFound = ref<boolean>(false);

  const fetchProjectsList = async (params: object | null = null) => {
    projectsList.value = [];

    let url = process.env.VUE_APP_BASE_API_URL + "/admin/project/list";

    if (params) {
      url += setQueryParams<typeof params>(params);
    }

    try {
      const response = await fetch(url);

      const {
        data: { records },
      } = await requestHandler<ProjectListResponse>(response);

      if (records?.length) {
        projectsList.value = records;
        isNotFound.value = false;
      } else {
        projectsList.value = [];
        isNotFound.value = true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  const addNewProject = async (body: ProjectModelType) => {
    const formData = new FormData();
    setFormData(formData, body);

    const url = `${process.env.VUE_APP_BASE_API_URL}/admin/project/add`;
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, options);

    const { data } = await requestHandler<ProjectActionResponse>(response);

    if (data) {
      projectsList.value.unshift(data);
      isNotFound.value = false;
    }
  };

  const editProject = async (guid: string, body: ProjectModelType) => {
    const areLinksValid = body.Urls.every((url) => url.Url.length >= 3);

    if (!areLinksValid || body.Name.length < 3) {
      throw new Error(
        JSON.stringify({
          Name: body.Name.length < 3,
          Urls: body.Urls.findIndex((url) => url.Url.length < 3),
        })
      );
    }

    const formData = new FormData();
    setFormData(formData, body);

    const url = `${process.env.VUE_APP_BASE_API_URL}/admin/project/update/${guid}`;
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, options);

    const { status, data } = await requestHandler<ProjectActionResponse>(
      response
    );

    if (status === "OK") {
      const editedProject = projectsList.value.find((item) => item.ID === guid);

      if (typeof editedProject !== "undefined") {
        editedProject.Name = data.Name;
        editedProject.UpdatedAt = data.UpdatedAt;
        editedProject.Urls = data.Urls;
        editedProject.IsActive = data.IsActive;
      }
    }

    return status;
  };

  const deleteProject = async (project: ProjectItemType) => {
    const formData = new FormData();
    formData.append("guid", project.ID as string | Blob);

    const url = `${process.env.VUE_APP_BASE_API_URL}/admin/project/remove`;
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, options);

    const result = await requestHandler<ProjectActionResponse>(response);

    projectsList.value.splice(projectsList.value.indexOf(project), 1);
    isNotFound.value = projectsList.value.length === 0;

    return result;
  };

  const toggleProject = async (project: ProjectItemType) => {
    const formData = new FormData();
    formData.append("active", (!project.IsActive).toString() as string | Blob);

    const url = `${process.env.VUE_APP_BASE_API_URL}/admin/project/toggle/${project.ID}`;
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, options);

    const { status } = await requestHandler<ProjectActionResponse>(response);

    project.IsActive = !project.IsActive;

    return status;
  };

  const deleteProjectUrl = async (model: ProjectModelType, index: number) => {
    if (typeof model.Urls[index].ID === "undefined") {
      model.Urls.splice(index, 1);
      return;
    }

    const formData = new FormData();
    setFormData(formData, {
      guid: model.Urls[index].ID,
    });

    const url = `${process.env.VUE_APP_BASE_API_URL}/admin/project/url/remove`;
    const options = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(url, options);
      await requestHandler<ProjectActionResponse>(response);

      model.Urls.splice(index, 1);

      const project = projectsList.value?.find((item) => item.ID === model.ID);

      if (project && project.Urls) {
        project.Urls.splice(index, 1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    projectsList,
    isLoading,
    isNotFound,
    fetchProjectsList,
    addNewProject,
    editProject,
    deleteProject,
    toggleProject,
    deleteProjectUrl,
  };
});
