<script setup>
import { ref } from "vue";
import AppMenuItem from "./AppMenuItem.vue";

const model = ref([
  {
    label: "Меню",
    items: [
      {
        label: "Создать",
        icon: "pi pi-fw pi-plus",
        to: "/create",
      },
      {
        label: "Поп-апы",
        icon: "pi pi-fw pi-home",
        to: "/",
      },
      {
        label: "Проекты",
        icon: "pi pi-fw pi-book",
        to: "/projects",
      },
      {
        label: "Пресеты поп-апов",
        icon: "pi pi-fw pi-clone",
        to: "/presets/popup",
      },
      {
        label: "Пресеты кнопкок",
        icon: "pi pi-fw pi-clone",
        to: "/presets/button",
      },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
