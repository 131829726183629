import { cloneDeep } from "lodash";
import type { ApiResponseBaseType } from "@/types/BasicType";
import type { CommonStyles } from "@/types/PopUpItemType";
import type { CSSProperties } from "vue";

export function generateId(): string {
  return (Math.random() * 1e4).toString(16);
}
export function setQueryParams<T>(params: T): string {
  const urlParams = new URLSearchParams();
  for (const item in params) {
    urlParams.set(item, String(params[item]));
  }
  return `?${urlParams.toString()}`;
}
export function setFormData(
  formData: FormData,
  obj: Record<string, unknown>,
  namespace = ""
): void {
  Object.entries(obj).forEach(([propertyName, propertyValue]) => {
    if (
      propertyName in obj &&
      propertyValue !== undefined &&
      propertyValue !== null &&
      propertyName[0] !== "_"
    ) {
      const formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((element, index) => {
          if (typeof element !== "object") {
            formData.append(formKey, element);
          } else {
            const tempFormKey = `${formKey}.${index}`;
            setFormData(formData, element, tempFormKey);
          }
        });
      } else if (typeof propertyValue === "object") {
        setFormData(
          formData,
          propertyValue as Record<string, unknown>,
          formKey
        );
      } else {
        if (typeof propertyValue === "string") {
          if (propertyValue.length > 0) {
            formData.append(formKey, propertyValue as string | Blob);
          }
        } else {
          formData.append(formKey, propertyValue as string | Blob);
        }
      }
    }
  });
}

type RecursiveObject = {
  [key: string]: RecursiveObject | unknown;
};
export function syncAllProperties(
  source: RecursiveObject,
  target: RecursiveObject
): void {
  // Копируем все ключи из source в target
  Object.keys(source).forEach((key) => {
    const sourceValue = source[key];

    if (
      sourceValue !== null &&
      typeof sourceValue === "object" &&
      !Array.isArray(sourceValue) &&
      !(sourceValue instanceof Date)
    ) {
      if (
        !(key in target) ||
        target[key] === null ||
        typeof target[key] !== "object" ||
        Array.isArray(target[key]) ||
        target[key] instanceof Date
      ) {
        target[key] = {};
      }
      // Рекурсивный вызов для копирования вложенных объектов
      syncAllProperties(
        sourceValue as RecursiveObject,
        target[key] as RecursiveObject
      );
    } else {
      // Если свойство не является объектом или является null, просто копируем его значение.
      target[key] = cloneDeep(sourceValue);
    }
  });

  // Удаляем ключи из target, которые отсутствуют в source для полной синхронизации
  Object.keys(target).forEach((key) => {
    if (!(key in source)) {
      delete target[key];
    }
  });
}

export function requestHandler<T = ApiResponseBaseType>(
  response: Response,
  errorMessage?: string
): Promise<T> {
  if (response.ok) {
    return response.json() as Promise<T>;
  } else if (response.status === 401) {
    location.href = "/login";
  }
  throw new Error(errorMessage ?? response.statusText);
}

type SortOptionType = {
  label: string;
  value: string;
  direction: string;
};

export const sortOptions: SortOptionType[] = [
  { label: "Сначала новые", value: "updatedAt", direction: "DESC" },
  { label: "Сначала старые", value: "updatedAt", direction: "ASC" },
  { label: "А-Я", value: "name", direction: "DESC" },
  { label: "Я-А", value: "name", direction: "ASC" },
];

export const formatStyles = (styles: Partial<CommonStyles>) => {
  const formatted: CSSProperties = {};

  if (styles.Left !== undefined && styles.Left !== null) {
    formatted.left = styles.Left + "px";
  }

  if (styles.Top !== undefined && styles.Top !== null) {
    formatted.top = styles.Top + "px";
  }

  if (styles.Width !== undefined && styles.Width !== null) {
    formatted.width = styles.Width + "px";
  }

  if (styles.Height !== undefined && styles.Height !== null) {
    formatted.height = styles.Height + "px";
  }

  if (styles.PaddingVertical !== undefined && styles.PaddingVertical !== null) {
    formatted.padding = styles.PaddingVertical + "px";
  }

  if (
    styles.PaddingHorizontal !== undefined &&
    styles.PaddingHorizontal !== null
  ) {
    formatted.padding += ` ${styles.PaddingHorizontal}px`;
  } else {
    formatted.padding += " 0";
  }

  if (styles.TextSize) {
    formatted.fontSize = styles.TextSize + "px";
  }

  if (styles.TextColor) {
    formatted.color = styles.TextColor;
  }

  if (styles.BackgroundColor) {
    formatted.backgroundColor = styles.BackgroundColor;
  }

  if (styles.BackgroundImage) {
    formatted.backgroundImage = `url(${styles.BackgroundImage.Url})`;
  }

  if (styles.BackgroundSize) {
    formatted.backgroundSize = styles.BackgroundSize;
  }

  if (styles.BorderWidth) {
    formatted.borderWidth = styles.BorderWidth + "px";
  }

  if (styles.BorderStyle) {
    formatted.borderStyle = styles.BorderStyle;
  }

  if (styles.BorderColor) {
    formatted.borderColor = styles.BorderColor;
  }

  if (styles.BorderRadius !== undefined && styles.BorderRadius !== null) {
    formatted.borderRadius = styles.BorderRadius + "px";
  }

  return formatted;
};

export const extractStyles = (data: Partial<CommonStyles>) => {
  const extracted: Partial<CommonStyles> = {};

  if (data.Left !== undefined && data.Left !== null) {
    extracted.Left = data.Left;
  }

  if (data.Top !== undefined && data.Top !== null) {
    extracted.Top = data.Top;
  }

  if (data.PositionType) {
    extracted.PositionType = data.PositionType;
  }

  if (data.ButtonPosition) {
    extracted.ButtonPosition = data.ButtonPosition;
  }

  if (data.Width !== undefined && data.Width !== null) {
    extracted.Width = data.Width;
  }

  if (data.Height !== undefined && data.Height !== null) {
    extracted.Height = data.Height;
  }

  if (data.TextSize) {
    extracted.TextSize = data.TextSize;
  }

  if (data.TextColor) {
    extracted.TextColor = data.TextColor;
  }

  if (data.BackgroundColor) {
    extracted.BackgroundColor = data.BackgroundColor;
  }

  if (data.BackgroundImage) {
    extracted.BackgroundImage = data.BackgroundImage;
  }

  if (data.BackgroundSize) {
    extracted.BackgroundSize = data.BackgroundSize;
  }

  if (data.BorderColor) {
    extracted.BorderColor = data.BorderColor;
  }

  if (data.BorderStyle) {
    extracted.BorderStyle = data.BorderStyle;
  }

  if (data.BorderWidth !== undefined && data.BorderWidth !== null) {
    extracted.BorderWidth = data.BorderWidth;
  }

  if (data.BorderRadius !== undefined && data.BorderRadius !== null) {
    extracted.BorderRadius = data.BorderRadius;
  }

  if (data.HorizontalAlign) {
    extracted.HorizontalAlign = data.HorizontalAlign;
  }

  if (data.VerticalAlign) {
    extracted.VerticalAlign = data.VerticalAlign;
  }

  if (data.AnimationType) {
    extracted.AnimationType = data.AnimationType;
  }

  if (data.AnimationDuration) {
    extracted.AnimationDuration = data.AnimationDuration;
  }

  return extracted;
};

export const passThroughPrime = (id: string, key = "input") => ({
  [key]: {
    id,
  },
});
